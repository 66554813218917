
import { EventBus } from "@/plugins/eventBus"
import Vue from "vue"
import SelectSpace, { Agency } from "@/components/SelectSpace.vue"
import axios, { AxiosError } from "axios"
import MobilePhoneNumberInput from "@/components/MobilePhoneNumberInput.vue"

export default Vue.extend({
    name: "SubscriptionSendMessage",
    components: { SelectSpace, MobilePhoneNumberInput },
    model: {
        event: 'emitAgencyId'
    },
    data: () => ({
        selectedSpace: null as null | Agency,
        spaceId: null as null | number,
        phone: null as null | string,
        spaceName: null as null | string,
        url: null as HTMLElement | null,
        errorPhone: false,
        errorSpace: false,
        sizeScreen: window.innerWidth
    }),
    computed: {
        phoneRequired() {
            return () => (!!this.phone === true) || this.$vuetify.lang.t('$vuetify.phone_required')
        }
    },
    methods: {
        subscriptionUrl() {
            let subUrl = ""
            if (this.selectedSpace && this.selectedSpace.url) {
                subUrl = new URL('/inscription/' + this.selectedSpace.url.replace('.dispojob.pro', ''), window.location.href).href
            }
            return subUrl
        },
        getFullSpace(space: Agency) {
            if (space) {
                this.selectedSpace = space
            }
        },
        copyText() {
            let temp = (this.$refs.url as HTMLElement) 
            navigator.clipboard.writeText(temp.innerHTML)
        },
        checkErrorPhone(error : boolean) {
            // get complex validation phone number result
            this.errorPhone = error
        },
        checkErrorSpace(error : boolean) {
            // get error of selected space
            this.errorSpace = error
        },
        sendSubscriptionMessage() {
            if (this.selectedSpace) {
                axios
                    .post(`/v1/sms`,
                    {"space_id": this.spaceId,
                    "sender": this.selectedSpace.name,
                    "phone": this.phone,
                    "message": `${this.selectedSpace.name} vous invite à créer votre dossier complet d'inscription : ${this.subscriptionUrl()}`})
                    .then(() => {
                        EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.sms_sent')})
                    })
                    .catch((error: AxiosError) => {
                        EventBus.$emit('snackbar', {axiosError: error.response})
                    })
            }
        }
    }
})
