
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"
import MobilePhoneNumberInput from "@/components/MobilePhoneNumberInput.vue"
import SelectSpace from "@/components/SelectSpace.vue"

interface Body {
  agency_id: string
  sex: string
  last_name: string,
  first_name: string,
  mobile_phone_number: string,
  email: string
}

export default Vue.extend({
  name: 'WorkerForm',
  components : {
    MobilePhoneNumberInput,
    SelectSpace
  },
  data: () => ({
    title: "",
    lastName: "",
    firstName: "",
    phone: "",
    email: "",
    agencyId: "",
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }),
  computed: {
    requiredRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    emailRule() {
      return (v: string) => (this.emailRegex.test(v) || !v) || this.$vuetify.lang.t('$vuetify.invalid_email')
    },
    stringLengthRule() {
      return (v: string) => ((v.length > 1 && v.length < 100) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    phoneNumberRule() {
      return (v: string) => (!v || (5 <= v.length && v.length <= 20)) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    emailOrPhoneRequired() {
      return (v: string) => (!!this.email === true|| !!this.phone === true) || this.$vuetify.lang.t('$vuetify.phone_or_email_required')
    },
  },
  watch: {
    phone: function() {
      (this.$refs.phoneAndMail as Vue & { validate: () => boolean }).validate()
    },
    email: function() {
      (this.$refs.phoneAndMail as Vue & { validate: () => boolean }).validate()
    },
  },
  methods: {
    createWorker() {
      const phoneMailForm = (this.$refs.phoneAndMail as Vue & { validate: () => boolean }).validate()
      const workerForm = (this.$refs.form as Vue & { validate: () => boolean }).validate()
      if ( phoneMailForm && workerForm)
      {
        let body = {
          agency_id: this.agencyId,
          sex: this.title,
          last_name: this.lastName,
          first_name: this.firstName,
          mobile_phone_number: this.phone,
          email: this.email,
        } as Body
        axios
          .post(`/v1/workers`, [body])
          .then((response: AxiosResponse) => {
            if ("worker_id" in response.data[0]) {
              EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.worker_successfully_created')})
              this.$router.push(`/workers`)
            } else {
              let textError = this.$vuetify.lang.t(`$vuetify.subscription_page.error.${response.data[0]}`)
              EventBus.$emit('snackbar', {message: textError, "color": "error"})
            }
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar', {axiosError: error.response})
          })
      }
    },
    cancel() {
      this.$router.push(`/workers`)
    }
  }
})
