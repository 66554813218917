
import { EventBus } from "@/plugins/eventBus"
import axios, { AxiosError, AxiosResponse } from "axios"
import Vue, { PropType } from "vue"
import DateTimePicker from "../DateTimePicker.vue"
import { format } from "date-fns"

export default Vue.extend({
  name: "SendSMSDialog.vue",
  components: {
    DateTimePicker
  },
  props: {
    value: { // used to make v-model works
      type: Boolean,
      default: false
    },
    selectedSW: { // super_worker selection from Parangon
      type: Array as PropType<number[]>,
      required: true,
    },
    title: {
      type: String,
      required: true
    },
    messageProp: {
      type: String,
      required: false
    },
    messageEditable: {
      type: Boolean,
      default: true,
      required: false
    },
    reminderEnabled: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      credit: 1,
      limitMessageSize: 160,
      gsmBasicCharacterSet: [
      '@','Δ','SP','0','¡','P','¿', 'p',
      '£','_','!','1','A','Q','a','q',
      '$','Φ','"','2','B','R','b','r',
      '¥','Γ','#','3','C','S','c','s',
      'è','Λ','¤','4','D','T','d','t',
      'é','Ω','%','5','E','U','e','u',
      'ù','Π','&','6','F','V','f','v',
      'ì','Ψ',"'",'7','G','W','g','w',
      'ò','Σ','(','8','H','X','h','x',
      'Ç','Θ',')','9','I','Y','i','y',
      'LF','Ξ','*',':','J','Z','j','z',
      'Ø','ESC','+',';','K','Ä','k','ä',
      'ø','Æ',',','<','L','Ö','l','ö',
      'CR','æ','-','=','M','Ñ','m','ñ',
      'Å','ß','.','>','N','Ü','n','ü',
      'å','É','/','?','O','§','o','à',
      ' ', '\n'
      ],
      gsmExtensionCharacterSet: ['|','^','€','{','}','[',']','~','\\'],
      messageCount: 0,
      unicode: false,
      type: 'GSM',
      credits: [160, 306, 460, 612],
      textAreaData: "", // The message's content
      disableSubmit: true,
      setReminderDate: false,
      addReminderDate: false,
      reminderDate: {dateObject: null as Date | null, dateString: "", timeString: ""},
      sendDate: {dateObject: null as Date | null, dateString: "", timeString: ""},
      valid: false, // form validation state
      loading: false, // loading state on submit button
      gsmCountExplanation: false,
    }
  },
  watch: {
    textAreaData() {
      this.countGSM(this.textAreaData)

      this.disableSubmit = false
      if ((this.messageCount >= this.credits[this.credits.length-1]) || !this.textAreaData.length) {
        this.disableSubmit = true
      }
    },
    inputValue: function (val: boolean) {
      if (val === true) { // we reset the component when it is shown
        this.init()
      }
    },
    addReminderDate(){
      if(this.addReminderDate) {
        let now = new Date()
        now.setSeconds(now.getSeconds() + 1);
        this.reminderDate = {
          dateObject: now,
          dateString: format(now, 'yyyy-MM-dd'),
          timeString: format(now, 'HH:mm:ss')}
        
      } else {
        this.reminderDate = {dateObject: null, dateString: "", timeString: ""}
      }
    },
  },
  computed: {
    inputValue: { // bind value prop to our v-model on v-dialog
      get(): boolean {
        return this.value
      },
      set(val: boolean) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /**
     * Resets the component's state
     */
    init()
    {
      // display snackbar error and hide component if no super_worker in selection
      if (this.selectedSW.length === 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.empty_selection'), color: 'error' })
        this.inputValue = false
        return
      }
      this.valid = false
      this.textAreaData =''
      if (this.messageProp) {
        this.textAreaData = this.messageProp
      }
      let date = new Date()
      this.type = 'GSM'
      this.addReminderDate = this.setReminderDate = this.unicode = false
      this.credit = 1
      this.limitMessageSize = this.credits[this.credit -1]
      // reset the sendDate value to now
      this.sendDate = {
        dateObject: date,
        dateString: format(date, 'yyyy-MM-dd'),
        timeString: format(date, 'HH:mm:ss')
      }
    },
    /**
    * Counts the number of sms needed for a message
    * depending on used character encoding system
    *
    * @param message The sms's content
    */
    countGSM(message: string)
    {
      // https://en.wikipedia.org/wiki/GSM_03.38
      let count = 0
      for (const character of message) {
        let basic = this.gsmBasicCharacterSet.includes(character)
        let gsm = this.gsmExtensionCharacterSet.includes(character)
        if (basic) {
          count = count+1
        }
        if (gsm) {
          count = count+2
        }
        this.type = 'GSM'
        if (!basic && !gsm) {
          
          this.type = 'UNICODE'
          this.unicode = true
          break
        }
      }

      // if standard and specific character not found in message
      // we translate message to unicode and count every character as 2
      if (this.unicode) {
        // convert string to unicode
        count = message.length*2
      }
      this.unicode = false
      this.messageCount = count
      this.checkCount()
    },
    /**
    * Checks if there if enough credits for sms count
    */
    checkCount()
    {
      // https://support.esendex.fr/article/sms-fr/nombre-de-caracteres-des-sms/
      this.credit = 1
      for (let index = 0; index < this.credits.length; index++) {
        if (this.messageCount > this.credits[index] && index !== this.credits.length-1) {
          this.credit++
          this.limitMessageSize = this.credits[index+1]
        }
      }
    },
    /**
    * Submit the form and send the SMS
    */
    submit()
    {
      if (this.sendDate.timeString.length < 8) {
        this.sendDate.timeString = this.sendDate.timeString + ':00'
      }
      // create body
      let body = {
        id: this.selectedSW,
        send_sms: {
          message: this.textAreaData,
          send_date: `${this.sendDate.dateString} ${this.sendDate.timeString}`,
        } 
      } as {id: number[], send_sms: {message: string, send_date: string, reminder_date?: string}}
      
      if (this.addReminderDate && this.reminderDate.timeString.length < 8) {
          body.send_sms.reminder_date = `${this.reminderDate.dateString} ${this.reminderDate.timeString + ':59'}`
      }
      // submit
      axios.post('/v1/workers/action', body)
      .then((response: AxiosResponse) => {
        EventBus.$emit("snackbar", { message: this.$vuetify.lang.t("$vuetify.success_send_cv") })
        this.inputValue = false // close dialog
      })
      .catch((error: AxiosError) => {
        EventBus.$emit("snackbar", { axiosError: error })
      })
      .finally(() => {
        this.loading = false
      })
    },
      ruleCheckTextArea()
      {
        return this.messageCount <= this.credits[this.credits.length-1] || this.$vuetify.lang.t("$vuetify.invalid_length")
      }
    }
  })
