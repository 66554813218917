
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

export default Vue.extend({
    name: "PostionsAutocompleteMixin",
    data: () => ({
        positions: [] as {name: string, position_id: number}[],
        selectedPositions: null as number[]|number|null,
        searchPositions: "" as string,
        spaceFilter: false,
        spaceId: null as number|null
    }),
    watch: {
      searchPositions(val :string) {
        this.getPositions(val)
      },
      spaceId() {
        this.getPositions()
      }
    },
    methods: {
      getPositions(search: string|null = null) {
        if (this.spaceFilter && !this.spaceId) {
          return
        }
        let url = `/v1/clients/positions?`
        if(this.spaceId) {
          url += `space_id=${this.spaceId}`
        }
        if (search) {
          url += `&name=${search}`
        }
        axios
        .get(url)
        .then((response: AxiosResponse) => {
          this.positions = response.data
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error.code})
        })
      },
      filterOverride (item:{name: string, position_id: number}) {
        return item
      }
    }
})
