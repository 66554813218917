
import Vue from "vue"
import WorkerForm from "@/components/workers/WorkerForm.vue"
import SubscriptionSendMessage from "@/components/workers/SubscriptionSendMessage.vue"
import { Agency } from "@/components/subscription/SubscriptionContactDetails.vue"
import SubscriptionExpressSendMessage from "@/components/workers/SubscriptionExpressSendMessage.vue"
import AppSendMessage from "@/components/workers/AppSendMessage.vue"

export default Vue.extend({
    components: {
    WorkerForm,
    SubscriptionSendMessage,
    SubscriptionExpressSendMessage,
    AppSendMessage
},
    data: () => ({
        selectedSpace: null as null | Agency
    })
})
