
import Vue from "vue"
import { History, Bold, Italic, Underline } from "tiptap-vuetify"
import "tiptap-vuetify/dist/main.css"

// Mixin use to get the same Tiptap WYSIWYG editor's configuration accross all instances
// more at https://github.com/iliyaZelenko/tiptap-vuetify?tab=readme-ov-file#get-started
export default Vue.extend({
    name: "TiptapConfigMixin",
    data: () => ({
        extensions: [
            History,
            Bold,
            Italic,
            Underline
        ]
    })
})
