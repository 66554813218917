
import Vue from "vue"
import { VueConstructor } from "vue/types/umd"

interface Position {
  id: number,
  name: string,
  totalDelegated: number,
  priority: number | null
}

interface Client {
  id: number,
  name: string,
  positions: Array<Position>
}

export default (Vue as VueConstructor<Vue>).extend({
  name: "ClientsFormatter",
  props: {
    clients: {
      type: Array as () => Array<Client>,
      default: []
    },
    maxVisiblePositions: {
      type: Number,
      default: 6
    },
    highlightedPositionId: { // use this prop in order to highligh visually one position
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      dialog: false, // show modal
    }
  },
  computed: {
    totalPositions() {
      let total = 0
      this.clients.forEach((client) => {
        total += client.positions.length
      })
      return total
    }
  },
  methods: {
    openModal() {
      this.dialog = true
    }
  }
})

