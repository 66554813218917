
import { EventBus } from "@/plugins/eventBus"
import Vue from "vue"
import SelectSpace, { Agency } from "@/components/SelectSpace.vue"
import axios, { AxiosError } from "axios"
import MobilePhoneNumberInput from "@/components/MobilePhoneNumberInput.vue"

export default Vue.extend({
    name: "AppSendMessage",
    components: { SelectSpace, MobilePhoneNumberInput },
    model: {
        event: 'emitAgencyId'
    },
    data: () => ({
        selectedSpace: null as null | Agency,
        spaceId: null as null | number,
        phone: null as null | string,
        disabled: false, 
        spaceName: null as null | string,
        url: null as HTMLElement | null,
        errorPhone: false,
        errorSpace: false,
        sizeScreen: window.innerWidth
        }),
    computed: {
        phoneRequired() {
            return () => (!!this.phone === true) || this.$vuetify.lang.t('$vuetify.phone_required')
        }
    },
    methods: {
        getFullSpace(space: Agency) {
            this.selectedSpace = space
        },
        copyText(data: string|null) {
            navigator.clipboard.writeText(
                data ? data : (this.$refs['url'] as HTMLElement).innerHTML)
        },
        checkErrorPhone(error : boolean) {
            // get complex validation phone number result
            this.errorPhone = error
        },
        checkErrorSpace(error : boolean) {
            // get error of selected space
            this.errorSpace = error
        },
        clearInputPhone() {
            this.phone = null
            this.disabled = false
        },
        sendSubscriptionMessage() {
            if (this.selectedSpace) {
                axios
                    .post(`/v1/sms`,
                    {"space_id": this.spaceId,
                    "sender": this.selectedSpace.name,
                    "phone": this.phone,
                    "message": `${this.selectedSpace.name} vous invite à télécharger l'appli : 
                    https://dispojob.com/application-mobile?c=${this.selectedSpace?.association_code}. 
                    le code agence pour l'inscription est ${this.selectedSpace.association_code}`})
                    .then(() => {
                        EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.sms_sent')})
                    })
                    .catch((error: AxiosError) => {
                        EventBus.$emit('snackbar', {axiosError: error.response})
                    })
            }
        }
    }
})
