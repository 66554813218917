
import Vue from "vue"
export default Vue.extend({
    name: "EmailRulesMixin",
    data: () => ({
        emailRegex: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }),
    methods: {
        emailRule(value: string) {
            return this.emailRegex.test(value) || this.$vuetify.lang.t("$vuetify.invalid_email")
        },
        emailsRule(values: Array<string>) {
            if (values.length === 0) {
                return this.$vuetify.lang.t("$vuetify.at_least_one_email")
            }
            let valid = true
            values.forEach((value: string) => {
                if (!this.emailRegex.test(value)) {
                    valid = false
                }
            })
            return valid || this.$vuetify.lang.t("$vuetify.invalid_email")
        },
    }
})
